import store from '@/store/index.js'
import { uploadConfigApi } from '@/api/app.js'
import OSS from 'ali-oss'

function getUploadConfig() {
	return new Promise((resolve, reject) => {
		if(store.state.uploadConfig && store.state.uploadConfig.expireTime > Date.now()) {
			resolve(store.state.uploadConfig)
		} else {
			uploadConfigApi().then(res => {
				store.commit('SET_UPLOAD_CONFIG', res.data)
				resolve(res.data)
			}).catch(err => {
				reject(err)
			})
		}
	})
}

async function uploadImageUtil(opt = {}) {
	let { keyType, directory, isPublic } = opt
	if(!keyType) keyType = 'zddManage_'
	if(!directory) directory = ''
	try{
		const uploadConfig = await getUploadConfig()
		// let sign = ''
		// let policy = ''
		// let url = 'https://' + uploadConfig.bucket + '.' + uploadConfig.endPoint + '/'
		let uid = localStorage.getItem('USER_ID') || ''
		if(!uid) return store.commit('LOGOUT')
		function uploadImageAsync(file) {
			console.log('file', file)
			const imgPath = URL.createObjectURL(file)
			let str = imgPath.split('/')
			let key = `${directory ? directory + '/' : ''}${uid}/${keyType}${str[str.length - 1]}.${file.name.split('.')[file.name.split('.').length - 1]}`
			let client = new OSS({
				accessKeyId: uploadConfig.accessKeyId, // 后台的临时签名ID
				accessKeySecret: uploadConfig.accessKeySecret, // 后台的临时签名密钥
				stsToken: uploadConfig.securityToken,
				endpoint: uploadConfig.endPoint, // 上传后的域名
				bucket: uploadConfig.bucket, // OSS仓库名
				expireTime: uploadConfig.expireTime
			});
			return new Promise((resolve, reject) => {
				fileToBlob(file).then(blob => {
					const options = {}
					if(isPublic) {
						Object.assign(options, {
							headers: { 'x-oss-object-acl': 'public-read' }
						})
					}
					client.put(key, blob, options).then(result => {
						if(result.res.statusCode == 200) {
							resolve({
								ossUrl: 'oss://' + key,
								url: result.url
							})
						} else {
							reject('上传失败')
						}
					}).catch(err => {
						reject(err)
						console.log('client.put', err)
					})
				})
			})
		}
		
		return {
			uploadConfig,
			uploadImageAsync
		}
	}catch(err){
		Promise.reject(err)
	}
	
}

function fileToBlob(file) {
	return new Promise((resolve, reject) => {
		let reader = new FileReader();
		reader.readAsDataURL(file)
		reader.addEventListener('load', (e) => {
				let base64 = e.target.result;
				let blob = dataURItoBlob(base64)
				resolve(blob)
		})
	})
}

function dataURItoBlob(base64Data) {
	let byteString;
	if (base64Data.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(base64Data.split(',')[1]); //base64 解码
	} else {
		// byteString = unescape(base64Data.split(',')[1]);
		byteString = decodeURI(base64Data.split(',')[1]);
	}
	let mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]; //mime类型 -- image/png
	// var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
	// var ia = new Uint8Array(arrayBuffer);//创建视图
	let ia = new Uint8Array(byteString.length); //创建视图
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	let blob = new Blob([ia], {
		type: mimeString
	});
	return blob;
}

 export default uploadImageUtil