<template>
  <div>
    <div class="pre-img" :style="{width, height}" v-if="showUploadImg && currentImg && !multiple">
      <LoadImage ref="loadImgRef" class="cur-img" :src="currentImg" preview :errorStyle="{height: '104px'}"></LoadImage>
      <div class="btn-delete" v-if="clearable" @click="handleClear"></div>
      <div class="row">
        <div class="btn-preview" @click="$refs.loadImgRef.handlePreview()"></div>
        <label :for="inputId">
          <div class="btn-upload"></div>
        </label>
      </div>
    </div>
    <label :for="inputId" v-else>
      <div v-if="!$slots.default">
        <div class="upload-img" :style="{width, height}">
          <img src="@/assets/images/add-plus.png">
          <div>添加图片</div>
        </div>
      </div>
      <slot></slot>
    </label>
    <input ref="inputRef" :id="inputId" type="file" accept="image/*" style="display: none;" :multiple="multiple" @change="chooseImage">
  </div>
</template>

<script>
import uploadImageUtil from '@/utils/uploadImageUtil.js'
import LoadImage from '@/components/LoadImage'
export default {
  components: {
    LoadImage
  },
  data() {
    return {
      currentImg: '',
      inputId: ''
    }
  },
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    // 是否显示上传后的图片
    showUploadImg: {
      type: Boolean,
      default: true
    },
    // 是否自动上传
    autoUpload: {
      type: Boolean,
      default: true
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '104px'
    },
    height: {
      type: String,
      default: '104px'
    }
  },
  watch: {
    imgUrl: {
      handler(newVal) {
        this.currentImg = newVal
      },
      immediate: true
    }
  },
  created() {
    // 生成不同input id
    this.inputId = 'input_'+(parseInt(Math.random()*10000) + Date.now()).toString()
  },
  methods: {
    async chooseImage(e) {
      console.log('选择图片',e)
      const fileList = e.target.files && e.target.files.length > 0 ? [...e.target.files] : []
      if(fileList.length <= 0) return
      if(this.autoUpload) {
        const { uploadImageAsync } = await uploadImageUtil()
        uploadImageAsync(fileList[0]).then(res => {
          console.log('上传成功', res)
          this.currentImg = res.url
          this.$emit('success', res)
        }).catch(err => {
          this.$tips({message: '上传图片失败', type: 'error'})
          this.$emit('fail', err)
        })
      } else {
        if(!this.multiple) {
          this.fileToBase64Async(fileList[0]).then(base64Url => {
            this.currentImg = base64Url
            this.$emit('success', {
              file: fileList[0],
              base64Url
            })
            this.$refs.inputRef.value = ''
          })
        } else {
          let count = 0
          const resList = []
          for (const file of fileList) {
            this.fileToBase64Async(file).then(base64Url => {
              resList.push({
                file,
                base64Url: base64Url
              })
              count++
              if(count == fileList.length) {
                this.$emit('success', resList)
                this.$refs.inputRef.value = ''
              }
            }).catch(err => {
              count++
              if(count == fileList.length) {
                this.$emit('success', resList)
                this.$refs.inputRef.value = ''
              }
            })
          }
          
        }
      }
      
    },
    fileToBase64Async(file) {
      return new Promise((resolve, reject) => {
        // 新建文件实例
        let fileReader = new FileReader();
        // 读取文件流
        fileReader.readAsDataURL(file);
        // 转为base64码
        fileReader.onload = e => {
          let base64 = e.target.result
          resolve(base64)
        }
      })
    },
    handleClear() {
      this.currentImg = ''
      this.$emit('clear')
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
}
.pre-img {
  position: relative;
  height: 104px;
  width: 104px;
  .row {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: rgba(0,0,0,.6);
  }
  .btn-preview {
    width: 20px;
    height: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAgVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9d3yJTAAAAKnRSTlMA9wcESR8PuJPvn1AnE+jbPePf08N/eXNXjWlbz7+Xhi8rIxexQxQLx18KLZvpAAABQ0lEQVQ4y81S2ZaCMAxtaGmhVVYFFHdHnbn//4EDaTkuR9/NU9J7m9ws4stsrprE2l2r9DtU9oYQjIyLXnGVeyhNwVYXT3CUAEhtd5JDqrizBCCJHnAD0P6hst4TYKJHPD+yG18KzztUgJGBkACLOQupAdDPjFtaAInHHVAxvpyaUGN0zgF2bhmI/3TA7yXSfYk0HuMZoRzrrYA1DyLDNmIdGSynboHVkImw4fcC8EJFg1TylxoUDxEcP69RBtEHIGZHAc2oTAVCFgjHieCApTgRahlKnDyhnUpsQGcW2XJYwUwidz4nixS6DG32wLaQ+m+IpzazW5CSvxnUvALcy6jd5j5qfR+1kAaoDn5ZqriyM8t5nR/XvSTGnw+GbBfzwTibPh9M2HQ4OWInV+LFom5xP9ptL8Ub067dWZs06iq+y/4BiDQlf7rRkAkAAAAASUVORK5CYII=);
    background-size: cover;
    cursor: pointer;
  }
  .btn-upload {
    width: 18px;
    height: 18px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAA2ZJREFUeF7tm01rFEEQht/3PwiC/gMR7+rFXOLRiyaKGA9+gR8HNaIoJEZQFI0e/AC/DkZEY7x49WIu6l1Ef4GC4H943cZZ2MxOz/bsVM/smu7jTnVN1dNVNdPVs8Q6H1zn/iMBSBHQEgFJtwHszW7/juSFNkxpJQUkvQEwnXN4meT+piE0DkDSCwAzHkeXSB5uEkKjACQ9AXBsgINPSR5vCkJjACTdB3A60LEHJM8EytYSawSApDsAzle0dJHkbMU5lcWjA5B0HcDlypb9m3CD5JUh5wZNiwpA0hyAhSBL/ELzJK/V1OGdHg2ApIsAbhoZfonkLSNda9REASDpLIC7xgafI3nPWKf9XkDSSQAPrQ3N9J0i+chSt2kESDoC4NkAA6cAvPXIlF3rTjlK8rkVBDMAkg4BWBrkPMkVSSqSI0lJ+0oAdafNkHxpAcEEgCS3csshzjuZMgDZ9RAI0yR9kRTMpjYASdsAfACwseSuU27lu9cHAQiE8BvAJMmvwd4WCFoAcG947k3PN9Y4HxIBPaAGRcIsycW2Abgt7GtfUetd+SoREAjhAEm3tR561I6AbEV/AtiUs6Jv5YcBUJIOv0huHtrzbKIJgMxIV5B2dxodPwDMkXR1oXCE1ID8REmTnd+uAtgOYIWkK7y1hxmAKpYMA6CK/iqyCUAVWlayKQJK3gStIIfqSSkQSspSLqVASgH/btAy0kJ0pRoQQslaJtWAVANSDfC2xKzTbZC+0iIoaQOArZ1z/G8k/wxSFnq9iRoQarsXQCdN3dZzvscp7/4+1PGuXGwABY3VBZLOn75RCMDTmTXpwTkLYgIo6VEWLqAPgGtuuH5cfpgcTEQG4DuYKWyi+AB8BLCrAIA3lKqkQWQA+dTtmrZKciJvZwJQtHKSYkfAp85Zwo7cvT+T3Fklkjy2j0UEuG+AHuccOEHSfUNUaxQ8vUYvBbInwUEAezLr3pN8VcvzbPLYALBwdmxTIJbzWWSNfg1IACISSDWgfw8zmk+BWEGQIiBFQN823jYFOpuk1Vjha6TXbeJ6+ximAIxsbEWNyW6wFcuNbpoAVOkHVPlzg9ECRVdT+CcMX0PEFRLXE/ifxgTJvgJe1hXekn336z5KGufxBYD7vvh7kROtHI6OEs0EYJRWow1b1n0E/AXK3c5QuOuLiQAAAABJRU5ErkJggg==);
    background-size: cover;
    cursor: pointer;
    margin-left: 16px;
  }
  .btn-delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAANlBMVEUAAAC9vb0ICAgAAAD19fWurq6CgoIAAADx8fG1tbUAAAAAAAAAAAC1tbUAAAAAAAAAAAD///+5YkYFAAAAEXRSTlPl9+UA/vbxjP324cQH96gqJ4WyEK8AAACRSURBVDjL7dM3DgMxDERRrqywyYH3v6xdeQCx+IThcqdSeIAgiTTKfozn7RPLZJxJaI8zCW0IQl5ZOLLwyML9O+q1FZtStrXbnOq+lMkt7jXAzSXlvGmuZcm4EGWYgowuSnLaJicpB1IOpBxJdgz5aL4MPw+6/MeAhKKgMvu9cBu0AjXXGmCv99iurXa78te8AWCKCjAUNu2eAAAAAElFTkSuQmCC);
    background-size: cover;
    cursor: pointer;
  }
}
.cur-img {
  // width: 104px;
  // height: 104px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border: 1px dashed var(--theme-color);
  }
}
// 上传图片
.upload-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  border: 1px dashed #D9D9D9;
  background-color: #FAFAFA;
  font-size: 14px;
  color: #505E5C;
  cursor: pointer;
  padding: 20px 0 10px;
  &:hover {
    border: 1px dashed var(--theme-color);
  }
  img {
    width: 18px;
    height: 18px;
  }
}
</style>